<template>
  <div class="miya-login">
    <home-bg></home-bg>
    <div class="miya-login-header">
      <img class="logo" src="@assets/images/home/miyaLOGO.svg" @click.stop="$router.push('/home')">
      <div class="empty"></div>
      <div class="btnHome" @click="$router.push('/home')"><i class="iconfont icon-home"></i>{{$t('home.home')}}</div>
      <miya-lang></miya-lang>
    </div>
    <div class="pageCard">
      <div class="titleBox">
        <div class="tab" @click="$router.push('/login')">{{$t('login.title1')}}</div>
        <div class="tab active">{{$t('login.title2')}}</div>
      </div>
      <div class="pageContent">
        <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
          <p class="inputName">{{$t('login.phone')}}</p>
          <el-form-item label="" prop="phone" label-width="0">
            <div class="phoneEmail">
              <el-select v-model="signupPrefix" class="prefix" placeholder="">
                <el-option v-for="(item, idx) in prefixList" :key="'prefix'+idx" :label="`${item.name} ${item.value}`" :value="item.value"></el-option>
              </el-select>
              <el-input v-model="ruleForm1.phone" :placeholder="$t('login.placeholder3')"></el-input>
            </div>
          </el-form-item>
          <p class="inputName">{{$t('login.phoneCode')}}</p>
          <el-form-item label="" prop="phoneCode" label-width="0">
            <el-input class="phoneCode" v-model="ruleForm1.phoneCode" :placeholder="$t('login.placeholder3')"></el-input>
            <p class="sendCode" @click="clickSendCode()">{{intervalIdx==0?$t('login.sendCode'):intervalIdx+' s'}}</p>
          </el-form-item> 
          <p class="tipString">{{$t('login.codeTip')}}</p>
          <div class="password-box">
            <div class="item">              
              <p class="inputName">{{$t('login.password')}}</p>
              <el-form-item label="" prop="password" label-width="0">
                <el-input show-password v-model="ruleForm1.password" :placeholder="$t('login.placeholder3')"></el-input>
              </el-form-item>
            </div>
            <div class="item">              
              <p class="inputName">{{$t('login.repeatPassword')}}</p>
              <el-form-item label="" prop="repeatPassword" label-width="0">
                <el-input show-password v-model="ruleForm1.repeatPassword" :placeholder="$t('login.placeholder3')"></el-input>
              </el-form-item>
            </div>
          </div>
          <p class="inputName">{{$t('invite.inviteCode')}}</p>
          <el-form-item label="" prop="inviteCode" label-width="0">
            <el-input v-model="ruleForm1.inviteCode" :placeholder="$t('login.placeholder4')"></el-input>
          </el-form-item>
        </el-form>
        <div class="checkbox">
          <div class="radio" :class="{checked: checked}" @click="checked=!checked"></div><p class="checkText">{{$t('login.checkedText4')}}<span class="click" @click="clickTos">{{$t('login.checkedText1')}}</span>{{$t('login.checkedText2')}}<span class="click" @click="clickTos2">{{$t('login.checkedText3')}}</span></p>
        </div>
        <div class="loginBtn" :class="{disabledBtn: disabledSignup}" @click.stop="clickSignup()">
          <div class="loading" v-loading="true" v-if="disabledSignup"></div>
          <div class="shadow"></div>
          <span>{{$t('login.signup')}}</span>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('login.sendCode')" :visible.sync="dialogCode" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="420px">
      <div class="captcha-box">
        <div class="captcha">
          <el-input size="medium" v-model="ruleForm1.captcha"></el-input>
        </div>
        <div class="vcImg">
          <img ref="vcImg" src="" @click="getCaptcha()">
        </div>          
      </div>    
      <div class="captcha-footer">
        <el-button class="cancel" type="info" size="medium" plain @click.stop="dialogCode = false">{{$t('login.cancel')}}</el-button>
        <el-button class="ok" type="info" size="medium" @click.stop="clickSendPhonecode()">{{$t('login.btnOK')}}</el-button>
      </div>
    </el-dialog>
    <miya-tos ref="miyaTos" @clickOK="checked=true"></miya-tos>
    <miya-tos2 ref="miyaTos2" @clickOK="checked=true"></miya-tos2>
  </div>
</template>
<script>
import miyaBtn from '@components/miyaBtn.vue'
import homeBg from '../home/homeBg.vue'
import miyaLang from '@components/lang.vue'
import miyaTos from './tos.vue'
import miyaTos2 from './tos2.vue'
import {setToken} from '@assets/js/http.js'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.errorPassword')))
      } else {
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.errorPassword')))
      } else if (value !== this.ruleForm1.password) {
        callback(new Error(this.$t('login.repeatPassword1')))
      } else {
        callback()
      }
    }
    return {
      signupPrefix: '+86',
      prefixList: [
        {name: '', value: '+86'},
        {name: '', value: '+1'},
        {name: '', value: '+852'},
        {name: '', value: '+853'},
        {name: '', value: '+886'},
        {name: '', value: '+81'},
        {name: '', value: '+82'},
        {name: '', value: '+65'},
        {name: '', value: '+60'},
        {name: '', value: '+63'},
        {name: '', value: '+66'},
        {name: '', value: '+91'},
        {name: '', value: '+44'},
        {name: '', value: '+49'},
        {name: '', value: '+1'},
        {name: '', value: '+33'},
        {name: '', value: '+39'},
        {name: '', value: '+61'},
        {name: '', value: '+64'},
        {name: '', value: '+255'},
        {name: '', value: '+233'},
        {name: '', value: '+254'},
        {name: '', value: '+46'},
        {name: '', value: '+20'},
        {name: '', value: '+51'},
        {name: '', value: '+591'},
        {name: '', value: '+57'},
        {name: '', value: '+593'},
        {name: '', value: '+52'},
        {name: '', value: '+84'},
        {name: '', value: '+358'},
        {name: '', value: '+31'},
      ],
      checked: false,
      ruleForm1: {
        phone: '',
        password: '',
        phoneCode: '',
        captcha: '',
        repeatPassword: '',
        inviteCode: '',
      },
      rules1: {
        phone: [
          {required: true, message: this.$t('login.errorPhone'), trigger: 'blur'}
        ],
        phoneCode: [
        ],
        captcha: [
          { required: true, message: this.$t('login.errorCaptcha'), trigger: 'blur' }
        ],
        password: [
          {validator: validatePass, trigger: 'blur'}
        ],
        repeatPassword: [
          {validator: validatePass2, trigger: 'blur'}
        ],
      },
      sendPhoneIdx: 0,
      captchId: null,
      disabledLogin: false,
      disabledSignup: false,
      dialogCode: false,
      intervalIdx: 0,
    }
  },
  created () {
  },
  mounted () {
    this.getData()
    let search = window.location.search
    if (search) {
      let searchUrl = search.substr(1)
      let obj = {}
      let list = searchUrl.split("&");
      for (let i = 0; i < list.length; i++) {
        let arr = list[i].split("=");
        obj[arr[0]] = arr[1];
      }
      if (obj.invitecode) {
        this.ruleForm1.inviteCode = obj.invitecode
      }
    }
  },
  methods: {
    getData () {
      this.rules1.phone[0].message = this.$t('login.errorPhone')
      this.rules1.captcha[0].message = this.$t('login.errorCaptcha')
      this.prefixList.map((val, idx) => {
        val.name = this.$t(`login.c${idx+1}`)
      })
    },
    countdown () {
      if (this.intervalIdx !== 0) {
        this.intervalIdx--
        setTimeout(() => {
          this.countdown()
        }, 1000)
      }      
    },
    getCaptcha () {
      this.captchId = new Date().getTime()
      this.$refs.vcImg.src = `/api/Check/Captcha?id=${this.captchId}`
    },
    clickSendCode () {
      if (!this.ruleForm1.phone) {
        this.$message.error(this.$t('login.errorPhone'))
        return
      }
      if (this.intervalIdx != 0) {
        return
      }
      this.intervalIdx = 60
      this.countdown()
      this.clickSendPhonecode()
    },
    clickSendPhonecode () {
      let data = {
        phone: `${this.signupPrefix} ${this.ruleForm1.phone}`,
        captchId: this.captchId,
      }
      this.$get(`/Check/SendPhoneCode`, data).then(res => {
        if (res.code == 200) {
          this.dialogCode = false
          this.$message.success(this.$t('login.sendSuccess'))
        } else if (res.code === 500) {
          this.$message.error(res.message)
        }
      })
    },
    clickSignup () {
      this.$refs.ruleForm1.validate(v => {
        if (v) {
          if (!this.checked) {
            this.$message.error(this.$t('login.error1'))
            return
          }
          if (!this.ruleForm1.phoneCode || this.ruleForm1.phoneCode.length < 6) {
            this.$message.error(this.$t('login.errorPhoneCode'))
            return
          }
          if (!this.disabledSignup) {
            this.disabledSignup = true
            this.$post(`/Check/PhoneReg?phoneCode=${this.ruleForm1.phoneCode}`, {
              account: `${this.signupPrefix} ${this.ruleForm1.phone}`,
              password: this.ruleForm1.password,
              beInvitedCode: this.ruleForm1.inviteCode
            }).then(res => {
              this.disabledSignup = false
              if (res.code === 500) {
                this.$message.error(res.message)
              } else if (res.code === 200) {
                this.$router.push('/login')
              }
            })
          }
        }
      })
    },
    clickTos () {
      this.$refs.miyaTos.show()
    },
    clickTos2 () {
      this.$refs.miyaTos2.show()
    }
  },
  beforeDestroy () {
  },
  watch: {
    language () {
      this.getData()
    },
  },
  components: {miyaBtn, homeBg, miyaLang, miyaTos, miyaTos2}
}
</script>
<style scoped lang="stylus">
.miya-login
  min-height 100vh
  user-select none
  position relative
  .miya-login-header
    position absolute
    z-index 5
    top 0
    left 0
    width 100%
    padding 24px 32px
    display flex
    justify-content space-between
    .logo
      width 106px
    .empty
      flex 1
    .btnHome
      padding 0 20px
      color $c2
      font-size $f16
      background-color #F0F0F0
      line-height 36px
      border-radius 50px
      cursor pointer
      margin-right 36px
      .iconfont
        margin-right 6px
        font-size $f16
      &:hover
        color $c1
  .pageCard
    width 600px
    border-radius 20px
    border 1px solid #D0CFCE
    box-shadow 0 16px 28px rgba(20,17,14, 0.08)
    position relative
    top 140px
    margin 0 auto
    .titleBox
      border-radius 19px 19px 0 0
      background-color #FAFAFA
      line-height 70px
      color $c1
      padding 0 90px
      display flex
      border-bottom 1px solid #EBEBEB
      .tab
        font-size $f24
        font-weight 600
        margin-right 60px
        position relative
        cursor pointer
      .active
        color $c8
        font-size 28px
        &:before
          content ''
          background-color $c8
          position absolute
          width 100%
          height 2px
          background-color $c8
          left 0
          bottom -1px
    .pageContent
      border-radius 19px
      background-color $c7
      padding 16px 90px 32px 90px
      position relative
      .inputName
        font-size $f16
        color $c1
        padding 10px 0 10px 0
        font-weight 600
      >>>.el-input__inner
        border-radius 10px
        border 1px solid #D0CFCE
        background-color $c7
      .phoneEmail
        display flex
        .prefix
          width 160px
          margin-right 8px
      .checkbox
        margin-top 40px
        display flex
        .radio
          width 18px
          height 18px
          border-radius 50%
          border 1px solid #D0CFCE
          cursor pointer
          margin-top 2px
          &:hover
            border 1px solid $c8
        .checked
          border 1px solid $c8
          position relative
          &:before
            content ''
            display block
            position absolute
            top 0
            left 0
            bottom 0
            right 0
            margin auto
            width 10px
            height 10px
            border-radius 50%
            background-color $c8
            border 2px solid $c7
        .checkText
          flex 1
          font-size 16px
          color $c1
          line-height 20px
          margin-left 8px
          .click
            color $c8
            cursor pointer
      .tipString
        font-size $f14
        color $c2
        line-height 18px
        margin-top -16px
        margin-bottom 12px
      .sendCode
        position absolute
        top 0
        right 16px
        color $c8
        cursor pointer
      .password-box
        display flex
        .item
          flex 1
          &:last-child
            margin-left 24px
      .loginBtn
        line-height 48px
        text-align center
        letter-spacing 2px
        color $c7
        border-radius 60px
        font-size $f20
        margin 8px 0 20px 0
        width 100%
        position relative
        cursor pointer
        background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%);
        &:hover
          background: linear-gradient(88deg, #FFCF20 0%, #FF7F10 100%);
        span
          position relative
          font-weight 600
          z-index 1
        .shadow
          position absolute
          width 90%
          height 90%
          left 50%
          top 14px
          background-color $c8
          opacity 0.5
          border-radius 50px
          filter: blur(20px) 
          transform translate3d(-50%,0,0)
          z-index 0
        .loading
          position absolute
          top 0
          left 0
          width 100%
          height 100%
          border-radius 50px
          overflow hidden
          >>>.el-loading-mask
            background-color rgba(255,255,255,0.3)
            backdrop-filter blur(3px)
          >>>.el-loading-spinner .circular
            width 28px
            height 28px
      .disabledBtn
        cursor not-allowed
      .account
        display flex
        justify-content center
        color $c2
        margin-left 0
      .forgetPassword
        color $c8
        text-align right
        cursor pointer
        margin-top -10px
        position relative
        z-index 5
  .captcha-box
    display flex
    margin-bottom 12px
    >>>.el-input__inner
      border-radius 10px
      border 1px solid #D0CFCE
      background-color $c7
    .captcha
      flex 1
    .vcImg
      width 150px
      margin-left 32px
      margin-top -5px
  .captcha-footer
    text-align center
    .cancel, .ok
      width 96px
      border-radius 50px
  >>>.el-dialog
    border-radius 20px
    .el-dialog__header
      font-size $f20
      font-weight 600
      padding 20px 0
      border-bottom 1px solid $c5
@media screen and (max-width: 800px) 
  .miya-login
    .miya-login-header
      padding 0.5rem 1rem
      .logo
        width 4rem
      .btnHome
        padding 0 1rem
        font-size 0.7rem
        line-height 1.6rem
        height 1.6rem
        .iconfont
          margin-right 0.3rem
          font-size 0.7rem
    .pageCard
      width 90%
      margin 0 auto
      top 5rem
      .titleBox
        padding 0 2rem
        line-height 3rem
        .tab
          font-size 1.1rem
      .pageContent
        padding 1rem 1.5rem
</style>